import React, { useCallback, useEffect, useState } from 'react';

import { getRelativeDate, getTomorrowDate, secureFetch } from '../../Util';
import { IEntry } from 'models/Entry';
import { IPrompt } from 'models/Prompt';
import { JournalEditor } from '../../components/JournalEditor';
import { DateTime } from 'luxon';
import { Paths } from 'models/Paths';
import { Box, Button, CircularProgress, FormControl, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { RouteIds } from '../../Constants';
import { ICircle } from 'models/Circle';
import { CircleHistory } from './CircleHistory';
import { IUser } from 'models/User';

export const TodayCircle = () => {
  const navigate = useNavigate();
  const circle = useRouteLoaderData(RouteIds.Circle) as ICircle;
  const sessionUser = useRouteLoaderData(RouteIds.Root) as IUser;

  const [journalEntriesForDay, setJournalEntriesForDay] = useState<IEntry[]>([]);
  const [loadingJournalEntriesForDay, setLoadingJournalEntriesForDay] = useState(false);

  const [prompt, setPrompt] = useState('');

  const { date } = useParams();

  if (circle === undefined || date === undefined) {
    throw Error();
  }

  const [selectedDate, setSelectedDate] = useState(date);

  useEffect(() => {
    // get current submitted prompt
    secureFetch(`/api/${Paths.Prompt.Base}/getUserPrompt/${circle.id}`, 'GET').then(async (res) => {
      if (res.ok) {
        const prompt = await res.json();
        setPrompt(prompt);
      }
    });
  }, [circle.id]);

  const updateOtherEntries = useCallback(
    (dateString: string) => {
      // get other user's entries for the day
      setLoadingJournalEntriesForDay(true);
      secureFetch(`/api/${Paths.Journal.Base}/${Paths.Journal.Day}`, 'POST', {
        circle: circle.id,
        datestring: dateString,
      }).then(async (res) => {
        if (res.ok) {
          const entries = await res.json();
          setJournalEntriesForDay(entries);
        }
        setLoadingJournalEntriesForDay(false);
      });
    },
    [circle.id]
  );

  useEffect(() => {
    setSelectedDate(date);
    updateOtherEntries(date ?? '');
  }, [date, updateOtherEntries]);

  const onPromptSubmit = () => {
    const newPrompt: IPrompt = {
      id: '',
      datestring: '',
      circle: circle.id,
      month: '',
      prompt: prompt,
      picked: false,
    };

    secureFetch(`/api/${Paths.Prompt.Base}/edit`, 'POST', {
      prompt: newPrompt,
    }).then((res) => {
      console.log(res);
    });
  };

  const prevDate = getRelativeDate(selectedDate, circle.timezone, -1);
  const nextDate = getRelativeDate(selectedDate, circle.timezone, 1);

  return (
    <Box>
      <CircleHistory />
      <JournalEditor selectedDate={selectedDate} circleId={circle.id} showPrompt={true} />
      <Stack direction="row" spacing={2}>
        <Button onClick={() => navigate(`../${prevDate.toISODate()}`)}>
          <ArrowLeft /> ({prevDate.toLocaleString()})
        </Button>
        <Button onClick={() => navigate(`../${nextDate.toISODate()}`)}>
          <ArrowRight /> ({nextDate.toLocaleString()})
        </Button>
      </Stack>
      <JournalEditor selectedDate={'0000-00-00'} circleId={circle.id} showPrompt={false} title="Notes" />
      {circle.members.length > 1 &&
        (loadingJournalEntriesForDay ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="h5">Everyone else's entries for {selectedDate}</Typography>
            {journalEntriesForDay.length > 0 ? (
              journalEntriesForDay
                .filter((entry) => entry.email !== sessionUser.name)
                .map((entry) => (
                  <Stack key={entry.email} spacing={1}>
                    <Typography variant="body1">{entry.email}'s Entry</Typography>
                    <Typography>{entry.content}</Typography>
                  </Stack>
                ))
            ) : (
              <Typography>Submit an entry to view others' entries!</Typography>
            )}
          </>
        ))}
      <Stack spacing={2}>
        <Typography variant="h5">{getTomorrowDate(circle.timezone)}'s prompt</Typography>
        <Typography variant="body1">
          Time until tomorrow:{' '}
          {DateTime.fromISO(DateTime.now().setZone(circle.timezone).toISODate() ?? '', { zone: circle.timezone })
            .plus({ days: 1 })
            .diff(DateTime.now(), ['hours', 'minutes'])
            .toHuman()}
        </Typography>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="prompt">What do you want to know..?</InputLabel>
          <OutlinedInput
            id="prompt"
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="What do you want to know..?"
            label="What do you want to know..?"
          />
          <Stack spacing={2}>
            <Button variant="contained" color="primary" onClick={onPromptSubmit}>
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Box>
  );
};
